import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import axios from 'axios';
import PrimeVue from 'primevue/config';
import SelectButton from "primevue/selectbutton";
import Carousel from 'primevue/carousel';
import Card from 'primevue/card';
import { createI18n } from 'vue-i18n';
import lang from "./libs"


// PrimeVue CSS fayllarini import qilish
import 'primevue/resources/themes/aura-light-green/theme.css';
import 'primevue/resources/primevue.min.css';
import 'primeicons/primeicons.css';

// Swiper.js CSS fayllarini import qilish

import { Swiper, SwiperSlide } from 'swiper/vue';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';
import 'swiper/swiper-bundle.css';

// Bootstrap CSS import qilish
import 'bootstrap/dist/css/bootstrap.css';

// Maxsus o'zgaruvchilarni import qilish
import './assets/variables.scss';

// I18n sozlash
const i18n = createI18n({
    locale: 'uz',
    legacy: false,
    // messages: {
    //     uz: {
    //         sample: {
    //             item1: 'hello world'
    //         }
    //     }
    // }
});

axios.defaults.baseURL = 'http://dr-mms.uz/';
if (window.location.href.indexOf('http://dr-mms.uz/') > -1) {
   axios.defaults.baseURL = 'http://dr-mms.uz/';
}
// Vue ilovasini yaratish
const app = createApp(App);

// PrimeVue-ni ulash
app.use(PrimeVue);
app.component('SelectButton', SelectButton);
app.component('Carousel', Carousel);
app.component('Card', Card);
app.component('Swiper', Swiper);
app.component('SwiperSlide', SwiperSlide);
// Vuex Store-ni ulash
app.use(store);

// I18n-ni ulash
app.use(i18n);
app.use(lang);
// Router-ni ulash
app.use(router);

// Axios-ni globalda o'rnatish
app.config.globalProperties.$axios = axios;

// Vue ilovasini biriktirish
app.mount('#app');
