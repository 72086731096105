import { createRouter, createWebHistory } from 'vue-router'


const routes = [
  {
    path: "/",
    component: () => import("@/components/layout/AppLayout"),

    children: [
      {
        name: "AppLayoutComponents",
        path: "/",
        component: () => import("@/views/components/AppLayoutComponents.vue"),
      },
    ],
  },
]

const router = createRouter({
  mode: 'history',
  scrollBehavior() {
    return { x: 0, y: 0 };
  },
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
