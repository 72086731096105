import { createI18n } from 'vue-i18n'
import en from './i18n/locales/en';
import uz from './i18n/locales/uz_latn';
import ru from './i18n/locales/ru';

const messages = { uz, ru, en };

const locales = [
    { code: 'uz', name: 'Uzb' },
    { code: 'ru', name: 'Russia' },
    { code: 'en', name: 'English' }

];

const i18n = createI18n({
    locales: locales,
    defaultLocale: 'uz',
    fallbackLocale: 'uz',
    messages,
    silentTranslationWarn: true,
    silentFallbackWarn: true,
})

export default i18n